import React from 'react';
import InputComp from '../InputComp';
import SelectComp from '../SelectComp';
import TextareaComp from '../TextareaComp';
import { cigars } from '../../consts/home';
import { Link, X } from 'lucide-react';
import { cigar_link_map, cigar_image_map } from '../../utils/link_map';
interface ProductDetailProps {
    cigarCode: string;
    selectedCigar: string;
    setSelectedCigar: (cigar: string) => void;
    product: any;
    description: string;
    setDescription: (description: string) => void;
    picturePreview?: string;
    fileInputRef?: any;
    handleFileChange?: (e: any) => void;
    handleUploadClick?: () => void;
    handleDeletePicture?: () => void;
    name: string;
    setName: (name: string) => void;
    brandName: string,
    setBrandName: (brandName: string) => void,
    vitola: string
    setVitola: (vitola: string) => void;
    isStoicCigar: boolean;
}

const ProductDetail: React.FC<ProductDetailProps> = (
    {
        cigarCode,
        selectedCigar,
        setSelectedCigar,
        product,
        description,
        setDescription,
        picturePreview,
        fileInputRef,
        handleFileChange,
        handleUploadClick,
        handleDeletePicture,
        name,
        setName,
        brandName,
        setBrandName,
        vitola,
        setVitola,
        isStoicCigar,
    }) => {

    return (
        <>
            <div className='mt-2 mb-2'>
                {selectedCigar !== 'non-stoic' && cigarCode !== "" && (
                    <>
                        <SelectComp
                        label=''
                        options={cigars}
                        value={selectedCigar}
                        onChange={setSelectedCigar}
                        disabled={true}
                        showIcon={false}
                        />
                    </>
                )}
            </div>
            {/* {selectedCigar !== 'non-stoic' && (
                <div className="mt-2 mb-2 bg-primary-blue-90">
                    <p className='block ml-1 p-2 text-white'>
                        {product && product.vitola || 'No vitola available'}
                    </p>
                </div>
            )} */}

            {/* Non Stoic Cigar */}
            {/* <div className='bg-primary-blue-90 p-4 flex flex-col items-center justify-center mt-4 rounded-2xl'> */}
            {/* {selectedCigar === 'non-stoic' && ( */}

            {/* {isStoicCigar && cigarCode === '' ? (
                    <>
                        {picturePreview ? (
                            <div className='relative'>
                                <img src={picturePreview} alt="Uploaded cigar" className='w-24 h-24 object-cover' />
                                <button
                                    onClick={handleDeletePicture}
                                    className="absolute top-0 right-0 bg-red-500 rounded-full p-1"
                                >
                                    <X size={16} color='white' />
                                </button>
                            </div>
                        ) : (
                            <button
                                onClick={handleUploadClick}
                                className='flex items-center space-x-2 text-teal-400 hover:text-teal-300 transition-colors'
                            >
                                <span>Upload Picture</span>
                            </button>
                        )}
                        <input
                            type="file"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept="image/*"
                            className="hidden"
                        />
                    </>
                ) : (<img src={product?.picture} className='w-24 h-24' alt="Cigar" />
                )
                } */}

            {/* )} */}
            {/* {selectedCigar !== 'non-stoic' && (
                    <p className='block mt-2 text-center text-gray-light'>
                        {product && product.short_description || 'No description available'}
                    </p>
                )}
            </div> */}

            {/* Cigar non stoic fiedls */}
            {
                selectedCigar === 'non-stoic' && (
                    <>
                        <div className="mt-2">
                            <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Brand Name
                            </label>
                            <InputComp
                                label='Brand'
                                placeholder='Enter Brand'
                                value={brandName}
                                onChange={setBrandName}
                            />
                        </div>
                        <div className="mt-2">
                            <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Cigar Name
                            </label>
                            <InputComp
                                label='Name'
                                placeholder='Enter Name'
                                value={name}
                                onChange={setName}
                            />
                        </div>
                        <div className="mt-2">
                            <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Vitola
                            </label>
                            <InputComp
                                label='Vitola'
                                placeholder='Enter Vitola'
                                value={vitola}
                                onChange={setVitola}
                            />
                        </div>

                        <div className='mt-2'>
                            <label className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Description
                            </label>
                            <TextareaComp
                                placeholder='Add notes like where you bought the cigar, or who gave it to you, etc.'
                                value={description}
                                onChange={setDescription}
                                rows={2}
                            />
                        </div>

                    </>
                )
            }

        </>
    );
};

export default ProductDetail;
