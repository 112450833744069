import React from 'react';
import { X, Album, MapPin } from 'lucide-react';
import RatingComp from '../../../components/RatingComp';
import JournalEntryDetails from '../Journal/JournalEntryDetails';
import { parseAddress } from '../../../utils/parseAddress';
import CigarIcon from "../../../assets/cigaricon.svg";

interface JournalDetailViewProps {
  selected: number;
  ratings: any[];
  onClose: () => void;
  isMobile: boolean;
  onUserClick?: (user: string) => void;
}

const JournalDetailView: React.FC<JournalDetailViewProps> = ({
  selected,
  ratings,
  onClose,
  isMobile,
  onUserClick
}) => {
  if (selected === 0) return null;

  const selectedRating = ratings.find((rating) => rating.id === selected);
  if (!selectedRating) return null;

  return (
    <div className={`${isMobile ? 'fixed inset-0 z-50 bg-primary-blue-90 overflow-y-auto z-10' : 'border border-primary-blue-90 rounded-2xl'} text-white`}>
      <div className='flex flex-col bg-primary-blue-90 rounded-2xl items-center justify-center p-8 relative text-center'>
        <div className='w-full flex justify-end'>
          <X
            className='w-6 h-6 cursor-pointer'
            onClick={onClose}
          />
        </div>

        {selectedRating.product_picture !== '' ? (
          <img src={selectedRating.product_picture} alt={selectedRating.product_picture} className='w-16 h-16' />
        ) : (
          <img src={CigarIcon} alt={selectedRating.product_picture} className="w-12 h-12 text-primary-blue-80" />
        )
        }
        <div className='mt-4'>{selectedRating.product_name}</div>

        {selectedRating.location && (
          <div className='bg-primary-blue-80 flex items-center mt-2 rounded-2xl px-2'>
            <MapPin className='w-5 h-5 mr-2' />
            <p className='mb-2 text-sm p-2 mt-1'>
              {parseAddress(selectedRating.location)}
            </p>
          </div>
        )}
        <div className='mt-2'>
          {selectedRating.product_short_description}
        </div>
        {onUserClick && (
          <div className='mt-2'>
            <button onClick={() => {
              onUserClick(selectedRating.user_id);
            }} className='text-lg text-secondary-gray cursor-pointer underline'>
              By {selectedRating.user_name}
            </button>
          </div>
        )}
      </div>

      <JournalEntryDetails selectedRating={selectedRating} />
    </div>
  );
};

export default JournalDetailView;
