import React, { useState } from 'react';
import { Album, X } from 'lucide-react';
import RatingComp from '../../../components/RatingComp';

interface JournalEntryDetails {
    selectedRating: any;
}

const JournalEntryDetails: React.FC<JournalEntryDetails> = ({ selectedRating }) => {
    const [showModal, setShowModal] = useState(false)
    const [currentImageIndex, setCurrentImageIndex] = useState(0)

    return (
        <div>

            {/* Experience Section */}
            <div className='p-4' >
                <label className="mt-4 block text-left text-sm font-medium text-white opacity-70 mb-1">
                    Experience
                </label>
                <p className="!text-white bg-primary-blue-90 p-4 text-center mt-2 mb-4 rounded-2xl">
                    {selectedRating?.experience_note || 'No experience available'}
                </p>

                <div className='mt-2'>
                    <p className='block text-left text-sm font-medium text-white opacity-70 mb-1'>
                        Rating
                    </p>

                    <div className='grid grid-cols-1 md:grid-cols-1 gap-4'>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Flavour
                            </label>
                            <RatingComp defaultRating={selectedRating?.flavour_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Aroma
                            </label>
                            <RatingComp defaultRating={selectedRating?.aroma_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Aroma
                            </label>
                            <RatingComp defaultRating={selectedRating?.aroma_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Burn
                            </label>
                            <RatingComp defaultRating={selectedRating?.burn_rating || 0} disabled={true} />
                        </div>
                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Appearance
                            </label>
                            <RatingComp defaultRating={selectedRating?.appearance_rating || 0} disabled={true} />
                        </div>

                        <div
                            className='flex justify-between bg-primary-blue-90 p-2 rounded-2xl'
                        >
                            <label className='text-left text-sm font-medium text-white opacity-70 mb-1'>
                                Overall rating
                            </label>
                            <RatingComp defaultRating={selectedRating?.rating || 0} disabled={true} />
                        </div>
                    </div>
                </div>

            </div>

            {/* Photos Section */}
            <div className='flex flex-col mt-4 p-4'>
                <div className='flex items-center justify-between'>
                    <h2 className='text-md font-semibold text-left text-gray-light'>
                        Photos
                    </h2>
                    <div className='flex items-center space-x-2'>
                        <span className='text-md font-semibold text-gray-light'>
                            {selectedRating?.journal_images?.length ?? 0}
                        </span>
                        <Album className='w-4 h-4 text-gray-light' />
                    </div>
                </div>
                {selectedRating?.journal_images?.length > 0 ?
                    <div className='flex flex-wrap mt-4 space-x-2 space-y-2 p-2'>
                        {selectedRating?.journal_images.map((image: any, index: number) => (
                            <img
                                key={index}
                                src={image.s3_url}
                                className='w-24'
                                alt='Journal Detail'
                                onClick={() => {
                                    setCurrentImageIndex(index);
                                    setShowModal(true);
                                }}
                            />
                        ))}
                    </div> : <p className='text-center text-gray-400 bg-primary-blue-80 py-2 px-4 rounded-lg mt-4'>
                        No photos available
                    </p>}
            </div>
            {showModal && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50" onClick={() => setShowModal(false)}>
                    <div className="relative">
                        <button
                            className="absolute -top-8 -right-2 p-1 text-white hover:text-gray-300"
                            onClick={(e) => {
                                e.stopPropagation();
                                setShowModal(false);
                            }}
                        >
                            <X size={24} />
                        </button>
                        <img
                            src={selectedRating?.journal_images[currentImageIndex].s3_url}
                            alt={selectedRating?.journal_images[currentImageIndex].name}
                            className="max-h-[80vh] max-w-[90vw] object-contain"
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default JournalEntryDetails;