import axios from 'axios';
import { useUserStore } from '../store/userStore';

axios.defaults.baseURL = process.env.REACT_APP_API_SERVER;
axios.defaults.withCredentials = true;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

const axiosInstance = axios.create();

let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });
  
  failedQueue = [];
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    const data = error.response?.data?.error;

    if (data === "User not found") {
      processQueue(data, null);
      useUserStore.getState().setUser(null);
      useUserStore.getState().setIsAuthenticated(false);
      window.location.href = '/login';
      return Promise.reject(data);
    }

    if (error.response.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        }).then(() => {
          return axiosInstance(originalRequest);
        }).catch(err => Promise.reject(err));
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const { data } = await axiosInstance.post('/user/refresh-token/');
        processQueue(null, data.access_token);
        return axiosInstance(originalRequest);
      } catch (refreshError) {
        processQueue(refreshError, null);
        useUserStore.getState().setUser(null);
        useUserStore.getState().setIsAuthenticated(false);
        const params = new URLSearchParams(window.location.search);
        if (params.has('addcigar')) {
          sessionStorage.setItem('addcigar', params.get('addcigar'));
        }
        window.location.href = '/login';
        return Promise.reject(refreshError);
      } finally {
        isRefreshing = false;
      }
    }

    // if (error.response.status === 500) {
    //   window.location.href = '/server-error';
    // }

    return Promise.reject(error);
  }
);

const axiosWrapper = apiCall => apiCall.then(response => response.data).catch(err => Promise.reject(err));


let api = {
    auth: {
        googleSignIn: (token) => axios.post('/auth/google/', { token }),
        login: (credentials) => axiosWrapper(axiosInstance.post('/user/authenticate/', credentials)),
        register: (credentials) => axiosWrapper(axiosInstance.post('/user/create/', credentials)),
        logout: () => axiosWrapper(axiosInstance.post('/user/logout/')),
        sendOTP: (email) => axiosWrapper(axiosInstance.post('/user/password-reset/', email)),
        validateOTP: (data) => axiosWrapper(axiosInstance.post('/user/password-reset/validate/', data)),
        updatePassword: (data) => axiosWrapper(axiosInstance.post('/user/password-reset/confirm/', data)),
        me: () => axiosWrapper(axiosInstance.get('/user/me/')),
        update: (data) => axiosWrapper(axiosInstance.put('/user/me/', data)),
        refreshToken: () => axiosWrapper(axiosInstance.post('/user/refresh-token/')),
    },
    profile: {
        updateProfilePicture: (formData) => axiosWrapper(axiosInstance.put('/user/me/', formData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })),
        deleteProfilePicture: () => axiosWrapper(axiosInstance.delete('/user/me/')),
        updatePassword: (data) => axiosWrapper(axiosInstance.patch('/user/me/password/', data)),
    },
    follower: {
        getFollowers: (userId) =>
            axiosWrapper(axiosInstance.get(userId ? `/followers/user/${userId}/followers/` : '/followers/user/followers/')),
        getFollowing: (userId) =>
            axiosWrapper(axiosInstance.get(userId ? `/followers/user/${userId}/following/` : '/followers/user/following/')),
        followUser: (userId) =>
            axiosWrapper(axiosInstance.post('/followers/user/following/', { follow_to: userId })),
        unfollowUser: (userId) =>
            axiosWrapper(axiosInstance.delete(`/followers/user/following/${userId}/`))
    },
  users: {
    listAllMembers: (page, pageSize, filter, sortBy) => {
      const params = {
        page: page,
        page_size: pageSize,
        filter: filter,
        sort_by: sortBy
      };

      return axiosWrapper(
        axiosInstance.get('/user/all-members/', {
          params: params
        })
      );
    },
    getMemberInfo: (userId) => axiosWrapper(axiosInstance.get(`/user/member/${userId}/`)),
  },
    cigars: {
        addCigar: (cigarData) => axiosWrapper(axiosInstance.post('/cigars/add_cigar/', cigarData, {
            headers: { 'Content-Type': 'multipart/form-data' }
        })),
      listCigars: (page, pageSize, sortBy = 'created_at', filterBy = "user") => {
        const params = {
          sort_by: sortBy,
          filter_by: filterBy,
        };

        if (page !== undefined) {
          params.page = page;
        }
        if (pageSize !== undefined) {
          params.page_size = pageSize;
        }

        return axiosWrapper(
          axiosInstance.get('/cigars/list_cigars/', {
            params: params
          })
        );
      },
        deleteCigar: (cigarId) => axiosWrapper(axiosInstance.delete(`/cigars/${cigarId}/delete_cigar/`)),
        validateCode: (code) => axiosWrapper(axiosInstance.post('/cigar-codes/validate_code/', { code })),
        updateCigar: (cigarId, cigarData) => axiosWrapper(axiosInstance.put(`/cigars/${cigarId}/update_cigar/`, cigarData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        })),  
    },
    rewards: {
        getRewards: () => axiosWrapper(axiosInstance.get('/rewards/')),
      redeemReward: (rewardId, addressId) => axiosWrapper(axiosInstance.post(`/rewards/${rewardId}/redeem/`, { address_id: addressId })),
        getRedemptionHistory: (page = 1, pageSize = 10, reward_id=-1) => axiosWrapper(axiosInstance.get('/rewards/history/', {
            params: {
                page: page,
                page_size: pageSize,
                reward_id:reward_id
            }
        })),
      getUserAddresses: () => axiosWrapper(axiosInstance.get('/address/')),
      addAddress: (street, city, state, zip, country, latitude, longitude, type) =>
        axiosWrapper(axiosInstance.post('/address/', {
          street: street,
          city: city,
          state: state,
          zip_code: zip,
          country: country,
          latitude: latitude,
          longitude: longitude,
          type: type,
        })),
      updateAddress: (addressId, street, city, state, zip, country, latitude, longitude, type) =>
        axiosWrapper(axiosInstance.put(`/address/${addressId}/`, {
          street: street,
          city: city,
          state: state,
          zip_code: zip,
          country: country,
          latitude: latitude,
          longitude: longitude,
          type: type,
        })),
    },
    googlePlaces: {
        getPlaceName: (lat, lng) =>
            axiosWrapper(axiosInstance.get(`/locations/get-place-name/`, { params: { lat, lng } })),
    },
    stats: {
        getStats: () => axiosWrapper(axiosInstance.get('/user-stats/stats/')),
        getTopCigars: () => axiosWrapper(axiosInstance.get('/user-stats/leaderboard/')),
        get90DayStats: () => axiosWrapper(axiosInstance.get('/user-stats/leaderboard90/')),
        getLocations: () => axiosWrapper(axiosInstance.get('/user-stats/locations/')),
        getTimeline: (page, itemPerPage) => axiosWrapper(axiosInstance.get('/user-stats/timeline/', {
            params: { page, page_size: itemPerPage }
        })),
    },
    retailers: {
        getRetailers: () => axiosWrapper(axiosInstance.get('/retailers/get_retailers/')),
    },
}

export {
    api,
    axiosInstance
}

export default api;
