import React, { useEffect } from 'react'
import DashboardLayout from '../../components/layouts/DashboardLayout'
import { Trophy, Calendar, ChevronDown, ChevronUp } from 'lucide-react'
import UserItem from '../../components/Dashboard/UserItem'
import AccountHeader from '../../components/Account/AccountHeader'
import HomeModals from './Modals/HomeModals'
import StatsCard from '../../components/Dashboard/StatsCard'
import RightSide from '../../components/Dashboard/RightSide'
import SpinnerComp from '../../components/SpinnerComp'
import GoogleMapComp from '../../components/Google/GoogleMapComp'
import { parseAddressesForMap } from '../../utils/parseAddress'
import SelectComp from '../../components/SelectComp'
import JournalDetailView from '../../components/Dashboard/Journal/JournalDetailView'
import useHome from '../../hooks/useHome'
import { useModalStore } from '../../store/useModalStore'
import TimelineRightSide from '../../components/Dashboard/Timeline/TimelineRightSide'
import { toast } from 'react-toastify'
import EmptyScreenComp from '../../components/EmptyScreenComp'
import { useNavigate } from 'react-router-dom'
import { useLocation } from 'react-router-dom';

const Home: React.FC = () => {
  const {
    stats,
    topCigars,
    last90Days,
    locations,
    loader,
    selected,
    selectedUser,
    onSelectUser,
    toggleDataDisplay,
    setSelected,
    refreshData,
    filter,
    filterOptions,
    handleFilterChange,
    handleMarkerClick,
    selectedFromMarker,
    setSelectedFromMarker,
    allCigarData,
    getTimelineData,
    timelineData,
    setTimelineData,
    timelineTotalRecords,
    selectedTimelinePage,
    getTimelineRewardHistoryData,
    showTimelineView,
    setShowTimelineView,
    onUserClick
  } = useHome()
  const { modalStates, updateModalState } = useModalStore();
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const add = queryParams.get('addcigar');
    
    if (add === 'true') {
      updateModalState('isModalOpen', true);
    }
  }, [location.search, updateModalState]);

  const navigate = useNavigate();

  const getTimeline = async (index: number) => {
    if (index === 2) {
      await getTimelineRewardHistoryData();
    }
    else {
      await getTimelineData();
    }
    setShowTimelineView(true);
  }

  const onPageChangeTimeline = async (page: number) => {
    await getTimelineData(page);
  }

  const renderLeaderboardItem = (user: any, index: number) => (
    <UserItem
      onClick={() => { onSelectUser(user.id) }}
      key={index}
      name={user.name}
      cigars={user.cigars}
      location={user.location}
      progress={user.progress}
      value={user.rank}
      valueLabel={['st', 'nd', 'rd', 'th'][Math.min(user.rank - 1, 3)]}
      picture={user.picture}
    />
  )

  const renderLeaderboardSection = (isMobile: boolean) => (
    <div className={`border border-primary-blue-90 rounded-2xl p-4 ${isMobile ? 'min-h-[250px]' : 'min-h-[300px]'}`}>
      <div className='flex justify-between items-center mb-2'>
        <h2 className='text-lg font-semibold'>All Time Leaderboard</h2>
        <Trophy size={18} className='text-yellow-500' />
      </div>
      {topCigars && topCigars.length > 0 ? (
        <div className='space-y-2 justify-center'>{topCigars.slice(0, 5).map(renderLeaderboardItem)}</div>
      ) : (
        <EmptyScreenComp
          title="No leaderboard data"

        />
      )}
    </div>
  )

  const renderLast90DaysSection = (isMobile: boolean) => (
    <div className={`p-4 mt-4 border border-primary-blue-90 rounded-2xl ${isMobile ? 'min-h-[250px]' : 'min-h-[300px]'}`}>
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-lg font-semibold'>Last 90 Days</h2>
        <Calendar size={18} />
      </div>
      {last90Days && last90Days.length > 0 ? (
        <div className='space-y-2'>{last90Days.slice(0, 5).map(renderLeaderboardItem)}</div>
      ) : (
        <EmptyScreenComp
          title="No recent activity"

        />
      )}
    </div>
  )

  const handleUserClick = (userId: string) => {
    onUserClick(userId);
    setSelectedFromMarker(0);
  }

  return (
    <DashboardLayout title='Dashboard' description=''>
      <div className="flex flex-col min-h-screen text-white">
        <AccountHeader
          title='Dashboard'
          showNavigation={false}
          onAddPress={() => updateModalState('isModalOpen', true)}
        />

        <HomeModals
          {...modalStates}
          setIsModalOpen={value => updateModalState('isModalOpen', value)}
          setIsCigarModalOpen={value =>
            updateModalState('isCigarModalOpen', value)
          }
        />

        <div className="flex flex-col lg:flex-row flex-grow">
          {/* Main Content Area */}
          <div className="w-full lg:w-3/4 px-4 flex flex-col">
            {/* Stat Cards */}
            <div className="flex flex-col lg:flex-row mb-4 space-y-4 lg:space-y-0 lg:space-x-4 order-1">
              {stats ? (
                <>
                  <div className="flex-1">
                    <StatsCard
                      title='All Stats'
                      stats={[
                        {
                          label: 'Cigars Logged',
                          value: stats.overall_stats.cigar_count.toLocaleString(),
                        },
                        {
                          label: 'Members',
                          value: stats.overall_stats.user_count.toLocaleString(),
                          // navigate to member on click
                          onClick: () => navigate('/dashboard/members')
                        },
                        { label: 'Rewards Earned', value: stats.overall_stats.total_reward.toLocaleString() }
                      ]}
                    />
                  </div>
                  <div className="flex-1">
                    <StatsCard
                      title='My Stats'
                      stats={[
                        {
                          label: 'Cigars Logged',
                          value: stats.user_stats.cigar_logged.toLocaleString(),
                        },
                        {
                          label: 'Total Rewards',
                          value: stats.user_stats.total_reward.toLocaleString(),
                          onClick: getTimeline
                        },
                        {
                          label: 'Rewards Balance',
                          value: stats.user_stats.reward_balance.toLocaleString(),
                          onClick: getTimeline
                        }
                      ]}
                      memberSince={stats.member_since}
                      ranking={stats.user_stats.ranking}
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="flex-1">
                    <StatsCard
                      title='All Stats'
                      stats={[
                        { label: 'Cigars Logged', value: '0' },
                        { label: 'Members', value: '0' },
                        { label: 'Rewards Earned', value: '0' }
                      ]}
                    />
                  </div>
                  <div className="flex-1">
                    <StatsCard
                      title='My Stats'
                      stats={[
                        { label: 'Cigars Logged', value: '0' },
                        { label: 'Total Rewards', value: '0' },
                        { label: 'Rewards Balance', value: '0' }
                      ]}
                      memberSince='2021-01-01'
                    />
                  </div>
                </>
              )}
            </div>
            {/* Right Panel Content (visible on mobile) */}
            <div className="lg:hidden order-2 mb-4">
              <RightSide
                selected={selected}
                user={selectedUser}
                setSelected={setSelected}
                topCigars={topCigars}
                isMobile={true}
                refreshData={refreshData}
              />

              {selected === 0 && selectedFromMarker === 0 && showTimelineView === false && (
                <>
                  {renderLeaderboardSection(true)}
                  {renderLast90DaysSection(true)}
                </>
              )}
            </div>

            {/* Map */}
            <div className="hidden md:block h-[100px] lg:h-[475px] rounded-2xl order-3">
              {/* Spinner */}
              {loader && (
                <SpinnerComp />
              )}
              {stats && !loader && (
                <GoogleMapComp
                  locations={parseAddressesForMap(locations, filter)}
                  optionalComponent={
                    <SelectComp
                      label="All Cigars"
                      options={filterOptions}
                      value={filter}
                      onChange={handleFilterChange}
                    />
                  }
                  onMarkerClick={handleMarkerClick}
                  filter={filter}
                />
              )}
            </div>

            {/* Journal Detail View - Mobile Version */}
            {selectedFromMarker !== 0 && !showTimelineView && (
              <div className="lg:hidden order-2 mb-4">
                <JournalDetailView
                  selected={selectedFromMarker}
                  ratings={allCigarData}
                  onClose={() => setSelectedFromMarker(0)}
                  isMobile={true}
                  onUserClick={handleUserClick}
                />
              </div>
            )}

          </div>

          {/* Right Panel (visible on desktop) */}
          <div className="hidden lg:block lg:w-1/4 ">
            {/* Timeline Desktop Right Panel */}
            {showTimelineView && (
              <TimelineRightSide
                timelineData={timelineData}
                isMobile={false}
                onClose={() => setShowTimelineView(false)}
                timelineTotalRecords={timelineTotalRecords}
                selectedTimelinePage={selectedTimelinePage}
                onPageChangeTimeline={onPageChangeTimeline}
              />
            )}
            <RightSide
              selected={selected}
              user={selectedUser}
              setSelected={setSelected}
              topCigars={topCigars}
              isMobile={false}
              refreshData={refreshData}
            />

            {selected === 0 && selectedFromMarker === 0 && showTimelineView === false && (
              <>
                {renderLeaderboardSection(false)}
                {renderLast90DaysSection(false)}
              </>
            )}

            {/* Desktop Version of Journal Detail View */}
            {selectedFromMarker !== 0 && !showTimelineView && (
              <div className="hidden lg:block z-[1000]">
                <JournalDetailView
                  selected={selectedFromMarker}
                  ratings={allCigarData}
                  onClose={() => setSelectedFromMarker(0)}
                  isMobile={false}
                  onUserClick={handleUserClick}
                />
              </div>
            )}
          </div>

          {/* Timeline Mobile Right Panel */}
          {showTimelineView && (
            <TimelineRightSide
            timelineData={timelineData}
            isMobile={true}
            onClose={() => setTimelineData([])}
            timelineTotalRecords={timelineTotalRecords}
            selectedTimelinePage={selectedTimelinePage}
            onPageChangeTimeline={onPageChangeTimeline}
          />
          )}
        </div>

            {/* Map */}
            <div className="mb-32 block md:hidden">
              {/* Spinner */}
              {loader && (
                <SpinnerComp />
              )}
              {stats && !loader && (
                <GoogleMapComp
              locations={parseAddressesForMap(locations, filter)}
                  optionalComponent={
                    <SelectComp
                      label="All Cigars"
                      options={filterOptions}
                      value={filter}
                      onChange={handleFilterChange}
                    />
                  }
                  onMarkerClick={handleMarkerClick}
              filter={filter}
                />
              )}
            </div>

      </div>

      
    </DashboardLayout>
  )
}

export default Home
