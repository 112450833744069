function cigar_link_map(type: string) {
    if (type === 'STOIC Wisdom') {
        return 'https://stoiccigars.com/wisdom';
    } else if (type === 'STOIC Courage') {
        return 'https://stoiccigars.com/courage';
    } else if (type === 'STOIC Justice') {
        return 'https://stoiccigars.com/justice';
    }
    else if (type === 'STOIC Moderation') {
        return 'https://stoiccigars.com/moderation';
    }

}

function cigar_image_map(type: string) {
    if (type === 'STOIC Wisdom') {
        return '/badges/stoic wisdom icon light blue.svg';
    } else if (type === 'STOIC Courage') {
        return '/badges/stoic courage icon light blue.svg';
    } else if (type === 'STOIC Justice') {
        return '/badges/stoic justice icon light blue.svg';
    } else if (type === 'STOIC Moderation') {
        return '/badges/stoic moderation icon light blue.svg';
    }
}

export { cigar_link_map, cigar_image_map };